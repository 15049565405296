import { $http } from '@/utils/https'
import Vue from 'vue'

import {
    CHANGE_SKIP,
    USER_TRANSACTIONS_REMOVE_ITEM,
    CHANGE_LOADING,
    SET_USER_TRANSACTIONS,
    SET_USER_TRANSACTIONS_LENGTH
} from './types/mutation-types'

import {
    GET_USER_TRANSACTIONS,
    DELETE,
    REVERSE
} from './types/action-types'

const state = {
    items               : [],
    itemsLength         : 0,
    isLoading           : false,
    skip                : 0,
    limit               : 15
}

const getters = {
    items               : state => state.items,
    itemsLength         : state => state.itemsLength,
    isLoading           : state => state.isLoading,
    skip                : state => state.skip,
    limit               : state => state.limit
}

const actions = {
    [GET_USER_TRANSACTIONS]     : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            var url = `${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/transactions?`;
            
            url += `limit=${(payload.limit ? payload.limit : state.limit)}`;
            url += '&';
            url += `skip=${(payload.skip ? payload.skip : state.skip)}`;
            
            if(payload.keywords){
				url += '&';
				url += `filters[keywords]=${payload.keywords}`;
			}
			
			if(payload.statusId){
				url += '&';
				url += `filters[status]=${payload.statusId}`;
			}
			
			if(payload.dateFrom){
				url += '&';
				url += `filters[date_from]=${payload.dateFrom}`;
			}
			
			if(payload.dateTo){
				url += '&';
				url += `filters[date_to]=${payload.dateTo}`;
			}
            
            console.log('url');
			console.log(url);
			console.log('');
            
            const response = await $http.get(url);
            
            if(response && response.data && 'data' in response.data){
                commit(SET_USER_TRANSACTIONS         , response.data.data)
                commit(SET_USER_TRANSACTIONS_LENGTH  , response.data.meta.total)
            }else{
                commit(SET_USER_TRANSACTIONS         , [])
                commit(SET_USER_TRANSACTIONS_LENGTH  , 0)
            }
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [DELETE]                    : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/transactions/${payload.id}`)
            
            commit(USER_TRANSACTIONS_REMOVE_ITEM, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [REVERSE]                   : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.post(`${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/transactions/${payload.id}/reverse`)
            
            commit(USER_TRANSACTIONS_REMOVE_ITEM, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_USER_TRANSACTIONS] (state, list) {
        state.items = list
    },
    [SET_USER_TRANSACTIONS_LENGTH] (state, length) {
        state.itemsLength = length
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [USER_TRANSACTIONS_REMOVE_ITEM] (state, params) {
        const index = state.items.findIndex(e => e.card_id === params.id)
        
        Vue.delete(state.items, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
